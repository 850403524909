import get from 'lodash/get';

import {
  MockRecordResource,
  MockRecordSingleton,
  StorefrontResource,
  SwellStorefrontCollection,
  SwellStorefrontResource,
  SwellStorefrontRecord,
  resolveAsyncResources,
} from '@swell/apps-sdk';

import { getCookie } from 'utils/cookie';

/** @typedef {import('@swell/apps-sdk').Swell} Swell */
/** @typedef {import('@swell/apps-sdk').SwellTheme} SwellTheme */

export const PAGE_RECORD_QUERY = Object.freeze({
  active: { $ne: false },
  fields: ['id', 'name', 'slug', 'number', 'title'],
  limit: 5,
});

export const PAGE_URL_PATTERN_REGEX = /:([\w._]+)/g;

export function shouldFetchPageData(pageConfig) {
  if (!pageConfig) {
    return false;
  }

  return Boolean(pageConfig.url) && Boolean(pageConfig.json);
}

export function shouldFetchPageRecord(pageConfig) {
  if (!shouldFetchPageData(pageConfig)) {
    return false;
  }

  return PAGE_URL_PATTERN_REGEX.test(pageConfig.url);
}

export function getStorefrontResource(resource) {
  return new Proxy(StorefrontResource, {
    construct(target, args) {
      const instance = new target(...args);
      Object.defineProperty(instance.constructor, 'name', {
        value: resource,
      });
      Object.defineProperty(instance, '_resourceName', {
        value: resource,
      });
      return instance;
    },
  });
}

export function getMockResource(resource) {
  return new Proxy(MockRecordResource, {
    construct(target, args) {
      const instance = new target(...args);
      Object.defineProperty(instance.constructor, 'name', {
        value: resource,
      });
      Object.defineProperty(instance, '_resourceName', {
        value: resource,
      });
      return instance;
    },
  });
}

export function getMockSingleton(singleton) {
  return new Proxy(MockRecordSingleton, {
    construct(target, args) {
      const instance = new target(...args);
      Object.defineProperty(instance.constructor, 'name', {
        value: singleton,
      });
      Object.defineProperty(instance, '_resourceName', {
        value: singleton,
      });
      return instance;
    },
  });
}

/**
 * @param {Swell} swell
 * @param {object} data
 */
export function compilePageData(swell, data) {
  const compiled = {};

  if (!data) {
    return compiled;
  }

  for (const [key, item] of Object.entries(data)) {
    const isObject = typeof item === 'object' && item !== null && !item._swell;

    if (isObject) {
      switch (item?._type) {
        case 'SwellStorefrontCollection':
          compiled[key] = new SwellStorefrontCollection(
            swell,
            data.id,
            {},
            () => compilePageData(swell, item.value),
          );
          break;

        case 'SwellStorefrontRecord':
          compiled[key] = new SwellStorefrontRecord(
            swell,
            item.value._collection,
            'all',
            {},
            () => compilePageData(swell, item.value),
          );
          break;

        case 'StorefrontResource':
          compiled[key] = new SwellStorefrontResource(
            swell,
            item.value._collection,
            () => compilePageData(swell, item.value),
          );
          break;

        default:
          if (item?._resource) {
            const Resource = getStorefrontResource(item._resource);
            compiled[key] = new Resource(() =>
              compilePageData(swell, item.value),
            );
          } else if (Array.isArray(item)) {
            compiled[key] = item.map((i) => compilePageData(swell, i));
          } else {
            compiled[key] = compilePageData(swell, item);
          }
      }
    } else {
      compiled[key] = item;
    }
  }

  return compiled;
}

/**
 * @param {object} pageProps
 * @param {object} record
 * @returns {string}
 */
export function getPageUrl(pageProps, record) {
  const { url } = pageProps;

  if (!record) {
    return url;
  }

  return url.replace(PAGE_URL_PATTERN_REGEX, (match, p1) => {
    return get(record, p1) || match;
  });
}

/**
 * @template T
 * @param {string} storefrontUrl
 * @param {object} page
 * @param {SwellTheme} theme
 * @param {object} record
 * @returns {Promise<T>}
 */
function getPageData(storefrontUrl, page, theme, record) {
  const pageProps = theme.props.pages.find((p) => p.id === page.id);

  if (!shouldFetchPageData(pageProps)) {
    return null;
  }

  const pageUrl = getPageUrl(pageProps, record);
  const dataUrl = `${storefrontUrl}${pageUrl}.json`;

  return fetch(dataUrl, {
    headers: {
      'Swell-Data': JSON.stringify(getCookie('swell-data') || {}),
    },
  }).then((res) => res.json());
}

/**
 * @param {object} page
 * @param {SwellTheme} theme
 * @param {object} record
 */
function getPageProps(page, theme, record) {
  return {
    id: page.id,
    getData: async (swell) => {
      try {
        const pageData = await getPageData(
          swell.storefront_url,
          page,
          theme,
          record,
        );

        return compilePageData(swell, pageData);
      } catch (e) {
        return null;
      }
    },
  };
}

/**
 * @param {Swell} swell
 * @param {SwellTheme} theme
 * @param {object} page
 * @param {object} record
 */
export async function getCanvasData(swell, theme, page, record) {
  // TODO: Investigate whether "id", "title", "description", etc. fields are needed
  const { id, title, description, content, sectionIds, props, getData } =
    getPageProps(page, theme, record);

  await theme.setCompatibilityConfigs(theme.globals?.configs);

  const pageData = getData && (await getData(swell, theme));

  theme.setCompatibilityData(pageData);

  await resolveAsyncResources(pageData);

  let [pageTitle, pageDescription, pageContent] = await Promise.all([
    typeof title === 'string'
      ? await theme.renderTemplateString(title, pageData)
      : typeof title === 'function'
      ? await title(pageData)
      : '',

    typeof description === 'string'
      ? await theme.renderTemplateString(description, pageData)
      : typeof description === 'function'
      ? await description(pageData)
      : '',

    content ?? sectionIds
      ? theme.renderAllSections(sectionIds, {
          ...props,
          ...pageData,
        })
      : theme.renderPage(
          {
            ...props,
            ...pageData,
          },
          pageData?.theme_template,
        ),
  ]);

  if (!pageTitle && pageContent?.page?.title) {
    pageTitle = pageContent.page.title;
  }
  if (!pageDescription && pageContent?.page?.description) {
    pageDescription = pageContent.page.description;
  }

  let pageProps = { ...props };
  const layoutProps = {
    page_title: pageTitle || pageData?.title || theme.globals.store?.name,
    page_description: pageDescription || pageData?.description,
    content_for_header: theme.getContentForHeader(),
    props,
  };

  const allSections = await theme.getAllSections();
  const pageSections = await theme.getPageSections(pageContent, false);
  const layoutSectionGroups = await theme.getLayoutSectionGroups(false);
  const sectionGroups = await theme.getPageSectionGroups(id);

  const themeGlobals = theme.globals;

  pageProps = {
    ...themeGlobals,
    ...pageData,
    pageProps,
  };

  return {
    pageProps,
    pageContent,
    layoutProps,
    allSections,
    pageSections,
    layoutSectionGroups,
    sectionGroups,
    themeGlobals,
    theme,
    pageId: page.id,
  };
}
